import {ParallaxProvider, useParallax} from "react-scroll-parallax";
import {ReactSVG} from "react-svg";
import React from "react";

const HeroImage = () => {
  const house = useParallax<HTMLDivElement>({
    translateY: ['0px', '50px'],
    startScroll: 0,
    endScroll: 500
  });
  const blob1 = useParallax<HTMLDivElement>({
    translateY: ['0px', '80px'],
    startScroll: 0,
    endScroll: 500
  });
  const blob2 = useParallax<HTMLDivElement>({
    translateY: ['0px', '80px'],
    startScroll: 0,
    endScroll: 500
  });

  return (
      <aside className="hero-image col-sm-4 col-sm-push-6">
        <div ref={house.ref} style={{zIndex:3, position:"absolute"}}>
          <ReactSVG src={'./svg/hero/house-phone.svg'} title={'Image showing a house within a phone'} />
        </div>
        <div ref={blob1.ref} style={{zIndex:2}}>
          <ReactSVG src={'./svg/hero/blob-1.svg'} style={{marginTop: "-100px", position:"absolute"}} title-={'background shape'} />
        </div>
        <div ref={blob2.ref} style={{zIndex:2}}>
          <ReactSVG src={'./svg/hero/blob-2.svg'} style={{marginTop: "-50px", position:"absolute"}} title-={'background shape'} />
        </div>
      </aside>
  );
};

const Hero = (data: any) => {

  return (
    <ParallaxProvider>
      <section className="hero container">
        <HeroImage />

        <div className="hero-content col-sm-6 col-sm-pull-4">
          <h1 className="title">{data.data && (data.data.title[0].text)}</h1>
          <h2 className="subtitle">{data.data && (data.data.subtitle[0].text)}</h2>
          {data.data && (
            <a
              className="button"
              href={`mailto:info@casante.com`}
              data-sitekey="6LfBwO0pAAAAAMkQrXFXQegj3ID2lXqXajgkF5F6"
              data-callback='onSubmit'
              data-action='click'
            >{data.data && (data.data.header_cta)}
            </a>
          )}
        </div>
      </section>
    </ParallaxProvider>
  )
}

export default Hero;