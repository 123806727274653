import React from 'react';
import ReactDOM from 'react-dom/client';
import './scss/style.css';
import Main from './Main';
import reportWebVitals from './reportWebVitals';
import {PrismicProvider} from "@prismicio/react";
import {client} from "./prismic";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

// RECAPTCHA
// Site Key: 6LfBwO0pAAAAAMkQrXFXQegj3ID2lXqXajgkF5F6
// Secret: 6LfBwO0pAAAAACkd3xd3GmGVtgPtz2qdRcX3jUwp

root.render(
  <React.StrictMode>
    <PrismicProvider client={client}>
    <Main />
    </PrismicProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
