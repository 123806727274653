import React from 'react';
import Footer from "./partials/Footer";
import Header from "./partials/Header";
import Hero from "./partials/Hero";
import {Parallax, ParallaxProvider} from "react-scroll-parallax";
import {useFirstPrismicDocument} from "@prismicio/react";

const socialMedia : any = {
  facebook: "",
  twitter: "",
  instagram: "",
  linkedin: "",
  angellist: ""
}


function Main() {
  const [document] = useFirstPrismicDocument()
  return (
    <div className={`page_wrapper ${document && 'loaded'}`}>
      <Header />
      <main>
        <Hero data={document && document.data} />
        <section className="container">
          <h1 className="heading-1 mod-center col-sm-8 col-sm-push-2">
            {document && (document.data.how_it_works_title[0].text)}
          </h1>
          {/*  HOW IT WORKS  */}
          {document && (document.data.how_it_works.map((block: any, index: number) => {
            let contentClass = '',
              imageClass = ''
            if (Math.abs(index % 2) === 1) {
              contentClass += 'col-sm-pull-5'
              imageClass += 'col-sm-push-7'
            }
            return (
              <ParallaxProvider>
                <div className={`col-sm-8 col-sm-push-2 rtb`} style={{padding:"0", alignItems: "center"}}>
                  <aside className={`col-sm-5 ${imageClass}`}>
                    <img src={block.rtb_image.url} alt={block.rtb_image.alt}/>
                  </aside>
                  <div className={`col-sm-7 ${contentClass}`}>
                    <h2 className="heading-4 mt-0 mb-1">{block.rtb_title[0].text}</h2>
                    <p className="m-0">{block.rtb[0].text}</p>
                  </div>
                </div>
              </ParallaxProvider>
            )}))}
        </section>
        <section className={'section-gradient'}>
          <div className="container">
            <div className="col-sm-8 col-sm-push-2 row">
              <div className="col-sm-8">
                <h2 className="heading-4 mt-0">{document && (document.data.section_title[0].text)}</h2>
                <p>{document && (document.data.section_content[0].text)}</p>
                <a
                  className="button"
                  href={`mailto:info@casante.com`}
                  data-sitekey="6LfBwO0pAAAAAMkQrXFXQegj3ID2lXqXajgkF5F6"
                  data-callback='onSubmit'
                  data-action='click'
                >Contact Casanté
                </a>
              </div>
              <div className="col-sm-4" style={{position:"relative"}}>
                <ParallaxProvider>
                  <Parallax speed={-2}>
                    <img className='app-preview' src={'caregiver-app-preview.png'} width={'100%'} alt="Concept of the Casanté application"/>
                  </Parallax>
                </ParallaxProvider>
              </div>
            </div>
          </div>
        </section>
        <section className={'container'} style={{marginBottom: '4rem'}}>
          <div className="col-sm-8 col-sm-push-2">
            <h2 className="heading-4">{document && (document.data.about_casante[0].text)}</h2>
            <p>{document && (document.data.about_casante_content[0].text)}</p>
            <h2 className="heading-4">{document && (document.data.casante_mission[0].text)}</h2>
            <p>{document && (document.data.casante_mission_content[0].text)}</p>
          </div>
        </section>
        <section style={{background: "linear-gradient(103deg, rgba(180, 212, 85, 0.5) 0%, rgba(207, 227, 145, 0.5) 100%)"}}>
          <div className="container" style={{paddingTop: "4rem", paddingBottom:"4rem"}}>
          { document && document.data.members.map((person: any) => {
            return (
              <div className={"people-person col-sm-8 col-sm-push-2 row"}>
                <div className="col-sm-4">
                  <img className="person-avatar" src={person.headshot.url} alt={person.headshot.alt}/>
                  <h4 className="person-name">{person.member_name[0].text}</h4>
                  <p className="person-title">{person.member_title}</p>
                </div>

                <div className="col-sm-8">
                  <h2 className="heading-4" style={{marginTop: 0}}>
                    Meet our founder
                  </h2>
                  {person.member_biography.map((textblock: any) => (
                    <p>{textblock.text}</p>
                  ))}
                  <a
                    className="button"
                    href={`mailto:info@casante.com`}
                    data-sitekey="6LfBwO0pAAAAAMkQrXFXQegj3ID2lXqXajgkF5F6"
                    data-callback='onSubmit'
                    data-action='click'
                  >Contact Casanté
                  </a>
                </div>
              </div>
            )})}
          </div>
        </section>
      </main>
      <Footer socialData={socialMedia} />

    </div>
  );
}

export default Main;
